import React, { Component } from 'react'
import styled from 'styled-components'
import { FaFilePdf, FaFileWord, FaFileImage, FaFileVideo } from 'react-icons/fa'
import { navigate } from 'gatsby'
import { sortObject } from '../utilities/Utility'

const Badge = styled.span`
  border-radius: 1em;
  background-color: #780032;
  height: 25px;
  font-weight: 700;
  font-size: medium;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #112e51;
  color: white;
`

const DDBadge = styled(Badge)`
  padding-left: 4px;
  padding-right: 4px;
`

const LinkStyle = styled.a`
  padding: 3px;
  color: #212121;
`

const FilterListItem = styled.li`
  margin: 0;
  margin-right: 3px;
  list-style: none;
  cursor: pointer;
`

const FilterUnOrderedList = styled.ul`
  display: flex;
  flexwrap: wrap;
  text-align: left;
  margin: 0 -10px;
  padding-left: 10px;
  max-width: 100%;
  margin-bottom: 10px;
`

const ArchiveTable = styled.table`
  margin-top: 0px;
  width: 100%;
  font-size: inherit;
`

class ArchivesGrid extends Component {
  static getMimeType(filetype) {
    if (filetype === '.png' || filetype === 'png') {
      return 'image/png'
    } else if (filetype === '.pdf') {
      return 'application/pdf'
    } else if (filetype === '.jpg') {
      return 'image/jpg'
    } else if (filetype === '.doc' || filetype === '.docx') {
      return 'application/msword'
    } else if (filetype === '.xls' || filetype === '.xlsx') {
      return 'application/vnd.ms-excel'
    } else if (filetype === '.ppt' || filetype === '.pptx') {
      return 'application/vnd.ms-powerpoint'
    } else if (
      filetype === '.txt' ||
      filetype === '.html' ||
      filetype === '.htm'
    ) {
      return 'text/html'
    }

    return 'application/octet-stream'
  }

  constructor(props) {
    super(props)

    this.state = {
      filter: 'All',
    }
  }

  getTitleIcon = (title, id, type) => {
    if (title.indexOf('.') > -1) {
      switch (true) {
        case title.indexOf('.pdf') > -1:
          return (
            <React.Fragment>
              <FaFilePdf height={14} width={20} color="red" />
              <a
                onClick={e => this.getFile(e, title)}
                onKeyDown={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                style={{ cursor: 'pointer' }}
                alt={title}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        case title.indexOf('.doc') > -1:
          return (
            <React.Fragment>
              <FaFileWord height={14} width={20} color="#2c599d" />
              <a
                onClick={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                style={{ cursor: 'pointer' }}
                alt={title}
                onKeyDown={e => this.getFile(e, title)}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        case title.indexOf('.jpg') > -1:
          return (
            <React.Fragment>
              <FaFileImage height={14} width={20} />
              <a
                onClick={e => this.getFile(e, title)}
                onKeyDown={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                alt={title}
                style={{ cursor: 'pointer' }}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        case type === 'Photo':
          return (
            <React.Fragment>
              <FaFileImage height={14} width={20} />
              <a
                onClick={e => this.getFile(e, title)}
                onKeyDown={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                alt={title}
                style={{ cursor: 'pointer' }}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        case type === 'Video':
          return (
            <React.Fragment>
              <FaFileVideo height={14} width={20} />
              <a
                onClick={e => this.getFile(e, title)}
                onKeyDown={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                alt={title}
                style={{ cursor: 'pointer' }}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        case title.indexOf('.') > -1:
          return (
            <React.Fragment>
              <FaFileImage height={14} width={20} />
              <a
                onClick={e => this.getFile(e, title)}
                onKeyDown={e => this.getFile(e, title)}
                role="button"
                tabIndex="-1"
                href={title}
                alt={title}
                style={{ cursor: 'pointer' }}
              >{` ${title}`}</a>
            </React.Fragment>
          )
        default:
          return <React.Fragment>{title}</React.Fragment>
      }
    } else {
      return <React.Fragment>{title}</React.Fragment>
    }
  }

  getFile = (e, filename) => {
    e.preventDefault()

    navigate('/Document', { state: { fileName: filename } })
  }

  displayTable = filter =>
    this.props.archive
      .map(item => item.node)
      .map(row => {
        const tableKey = `${row.Title}${row.ID}`
        if (filter === 'All') {
          return (
            <tr key={tableKey}>
              <th scope="col" style={{ width: '400px', paddingLeft: '5px' }}>
                {this.getTitleIcon(`${row.Title}`, row.ID, row.Type)}
              </th>
              <th scope="col">{row.Author}</th>
              <th scope="col">{row.PubDate}</th>
              <th scope="col" style={{ width: '105px' }}>
                {row.Type}
              </th>
            </tr>
          )
        } else if (filter === 'Photo') {
          if (row.Type === 'Photo') {
            return (
              <tr key={tableKey}>
                <th scope="col" style={{ width: '400px', paddingLeft: '5px' }}>
                  {this.getTitleIcon(`${row.Title}`, row.ID, row.Type)}
                </th>
                <th scope="col">{row.Author}</th>
                <th scope="col">{row.PubDate}</th>
                <th scope="col" style={{ width: '105px' }}>
                  {row.Type}
                </th>
              </tr>
            )
          }
        } else if (filter === 'Video') {
          if (row.Type === 'Video') {
            return (
              <tr key={tableKey}>
                <th scope="col" style={{ width: '400px', paddingLeft: '5px' }}>
                  {this.getTitleIcon(`${row.Title}`, row.ID, row.Type)}
                </th>
                <th scope="col">{row.Author}</th>
                <th scope="col">{row.PubDate}</th>
                <th scope="col" style={{ width: '105px' }}>
                  {row.Type}
                </th>
              </tr>
            )
          }
        }
        return null
      })

  onPress = (e, item) => {
    e.preventDefault()

    this.setState({
      filter: item,
    })
  }

  render() {
    const newFiltered = sortObject(this.props.filtered)

    const renderedList = Object.keys(newFiltered).map((item, i) => {
      const rlKey = `${item}${i}`
      return (
        <FilterListItem key={rlKey} data-id={i}>
          <LinkStyle key={rlKey} onClick={e => this.onPress(e, item)}>
            {item}
          </LinkStyle>
          {this.props.filtered[item].toString().length > 1 ? (
            <DDBadge onClick={e => this.onPress(e, item)}>
              {this.props.filtered[item]}
            </DDBadge>
          ) : (
            <Badge onClick={e => this.onPress(e, item)}>
              {this.props.filtered[item]}
            </Badge>
          )}
        </FilterListItem>
      )
    })

    return (
      <div>
        <h1 style={{ marginTop: '15px' }}>Archives</h1>
        <FilterUnOrderedList className="tableFilter">
          {renderedList}
        </FilterUnOrderedList>

        <ArchiveTable>
          <tbody>
            <tr>
              <th
                scope="col"
                className="tableHeader"
                style={{ paddingLeft: '5px' }}
              >
                <b>Title</b>
              </th>
              <th scope="col">
                <b>Author</b>
              </th>
              <th scope="col">
                <b>Publish Date</b>
              </th>
              <th scope="col">
                <b>Type</b>
              </th>
            </tr>
          </tbody>
          {this.displayTable(this.state.filter)}
        </ArchiveTable>
      </div>
    )
  }
}

export default ArchivesGrid
