import React from 'react'
import Layout from '../components/Layout'
import ArchiveStaticQuery from '../components/ArchiveStaticQuery'

const IndexPage = () => (
  <Layout>
    <ArchiveStaticQuery />
  </Layout>
)

export default IndexPage
