import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ArchivesGrid from './ArchivesGrid'

const ArchiveStaticQuery = props => (
  <StaticQuery
    query={graphql`
      query allArchiveContent {
        allBtswebViewtypeJson {
          edges {
            node {
              ID
              Title
              Caption
              Author
              ApprovalStatus
              Status
              Type
              ViewType
              PubDate
            }
          }
        }
      }
    `}
    render={data => {
      const PhotoTotals = data.allBtswebViewtypeJson.edges
        .filter(row => {
          return (
            row.node.Status === 'Archived' &&
            (row.node.Type === 'Photo' ||
              row.node.Type === 'Video' ||
              row.node.Type === 'Documents')
          )
        })
        .reduce((totals, item) => {
          totals[item.node.Type] = (totals[item.node.Type] || 0) + 1 // eslint-disable-line no-param-reassign
          return totals
        }, {})

      PhotoTotals.All = data.allBtswebViewtypeJson.edges.filter(row => {
        return (
          row.node.Status === 'Archived' &&
          (row.node.Type === 'Photo' ||
            row.node.Type === 'Video' ||
            row.node.Type === 'Documents')
        )
      }).length

      return (
        <ArchivesGrid
          archive={data.allBtswebViewtypeJson.edges.filter(row => {
            return (
              row.node.Status === 'Archived' &&
              (row.node.Type === 'Photo' ||
                row.node.Type === 'Video' ||
                row.node.Type === 'Documents')
            )
          })}
          filtered={PhotoTotals}
        />
      )
    }}
  />
)

export default ArchiveStaticQuery;
